import styled from 'styled-components';
import { rem } from 'polished';

export const FormContainer = styled.form`
  margin: ${({ centerAtDesktop }) => centerAtDesktop && 'auto'};
  margin-bottom: ${({ theme, hidePortals }) => hidePortals && theme.spacing.xl};
  ${({ theme }) => theme.media.smallTablet} {
    border: ${({ theme }) =>
      `solid ${rem(1)} ${theme.colors.backgroundAlternative}`};
    padding: ${({ theme }) => theme.spacing.xs};
    align-self: flex-start;
    z-index: 1;
    width: fit-content;
    background: ${({ theme }) => theme.colors.background};

    ${({ theme }) => theme.media.desktop} {
      margin-bottom: 0;
    }
  }
`;

export const PostcodeContainer = styled.div`
  border: ${({ theme }) =>
    `solid ${rem(1)} ${theme.colors.backgroundAlternative}`};
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};

  ${({ theme }) => theme.media.smallTablet} {
    border: none;
  }
`;

export const InputWrapper = styled.div`
  ${({ theme }) => theme.media.smallTablet} {
    transform: translateY(-2px);
  }

  label ~ div {
    pointer-events: none;
  }

  input {
    border: 0;

    &:focus {
      border: 0;
    }
  }
`;
