import React from 'react';
import { Text, Box } from '@strikelabs/luna';

import PostcodeSearch from 'components/Pages/Homepage/Sections/Hero/PostcodeSearch';
import Image from 'components/Image';

import { Outer, Inner, ImageContainer } from './style';

const BookVal = () => (
  <Outer>
    <Inner>
      <ImageContainer>
        <Image src="illustrations/sold.png" alt="Sold illustration" />
      </ImageContainer>
      <Text as="h2" ext mb={4}>
        Ready for a better way to sell your home?
      </Text>
      <Box width={[1, 1, 1, 5 / 10]} textAlign="center">
        <Text as="h3" ext mb={4}>
          If you want to save thousands and take the stress out of your sale, it
          all starts with a valuation.
        </Text>
        <PostcodeSearch centerAtDesktop />
      </Box>
    </Inner>
  </Outer>
);

export default BookVal;
