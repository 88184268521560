import React from 'react';
import {
  TextInput,
  Button,
  Box,
  ShowBreakpoint,
  HideBreakpoint,
  useIsMedia,
  useToast,
} from '@strikelabs/luna';
import { identifier } from '@strikelabs/pax';
import { POSTCODE_REGEX } from '@strikelabs/vega';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import formatPostcode from 'utils/formatPostcode';
import Link from 'components/Link';

import { FormContainer, PostcodeContainer, InputWrapper } from './style';

const PostcodeSearch = ({ centerAtDesktop, hidePortals, handleCtaClick }) => {
  const formik = useFormik({
    initialValues: {
      postcode: '',
    },
    validationSchema: Yup.object({
      postcode: Yup.string().trim().matches(POSTCODE_REGEX),
    }),
  });

  const { handleChange, handleBlur, values, isValid } = formik;

  const { pushToast, TOAST_TYPE } = useToast();

  const media = useIsMedia();

  const hasNumber = /\d/;

  const handleSubmit = () => {
    handleCtaClick();
    if (!isValid) {
      /**
       * Extra check to determine it's not a partial postode
       */
      if (!hasNumber.test(values.postcode)) {
        return pushToast({
          type: TOAST_TYPE.ERROR,
          message: (
            <>
              That doesn&apos;t look like a valid postcode. Are you{' '}
              <Link to={`/property-for-sale/search?name=${values.postcode}`}>
                searching for a property
              </Link>{' '}
              to buy?
            </>
          ),
          options: {
            duration: 10000,
          },
        });
      }
    }
    window.location = `/book-valuation?postcode=${formatPostcode(
      values.postcode
    )}`;
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <FormContainer
      {...identifier({ name: 'hero-form', requireId: false })}
      action="#"
      centerAtDesktop={centerAtDesktop}
      hidePortals={hidePortals}
    >
      <PostcodeContainer>
        <Box
          width={['100%', '100%', '100%', '100%', '180px', '230px']}
          mr={['inherit', 'inherit', 'inherit', 'inherit', 1]}
          mt={media.isMobile ? '-19px' : -1}
          height={['auto', 'auto', '50px']}
        >
          <InputWrapper>
            <TextInput
              required
              id="postcode"
              name="postcode"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={
                media.desktop && !media.giant
                  ? 'Enter postcode'
                  : "What's your postcode?"
              }
              value={values.postcode}
              enterkeyhint="go"
              onKeyDown={handleKeyDown}
              label="Postcode input"
              pillLabelIfActive
            />
          </InputWrapper>
        </Box>
        <ShowBreakpoint breakpoint="smallTablet">
          <Button onClick={handleSubmit} id="hero-cta">
            Book a valuation
          </Button>
        </ShowBreakpoint>
      </PostcodeContainer>
      <HideBreakpoint breakpoint="smallTablet">
        <Box mt={0}>
          <Button onClick={handleSubmit} id="hero-cta" fullWidth>
            Book a valuation
          </Button>
        </Box>
      </HideBreakpoint>
    </FormContainer>
  );
};

PostcodeSearch.propTypes = {
  centerAtDesktop: PropTypes.bool,
  hidePortals: PropTypes.bool,
  handleCtaClick: PropTypes.func,
};

PostcodeSearch.defaultProps = {
  centerAtDesktop: false,
  hidePortals: false,
  handleCtaClick: () => null,
};

export default PostcodeSearch;
