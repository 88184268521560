import styled from 'styled-components';

import { Container } from 'components/common';

export const Outer = styled.div`
  background: ${({ theme }) => theme.colors.backgroundLight};
  padding: ${({ theme }) => `${theme.spacing.lg} 0`};

  ${({ theme }) => theme.media.desktop} {
    padding: ${({ theme }) => `${theme.spacing.xxl} 0`};
  }
`;

export const Inner = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ImageContainer = styled.div`
  width: 124px;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;
