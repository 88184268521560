import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import SavingsSlider from 'components/SavingsSlider';
import HowWeSellForFree from 'components/HowWeSellForFree';
import { TYPES } from 'schemas';
import Checklist from 'components/Checklist';
import { GreyWrapper } from 'components/common';
import BookVal from 'components/BookVal';
import BrandImg from 'images/strike.png';
import { CHECKLIST } from 'constants/sell-your-home-for-free';
import {
  Footer,
  Portals,
  HeroTitle,
  Disclaimer,
} from 'components/Pages/LandingPages/Sections';
import { Hero, ESTAS } from 'components/Pages/Homepage/Sections';

const SellYourHomeForFree = () => {
  return (
    <Layout noFooter>
      <SEO
        title="Strike - Online Estate Agents. Sell your house for FREE"
        description="Sell Your House For Free With A Simple And Transparent Online Estate Agency Service. Go On Strike. Strike A Better Deal."
        canonical="/"
        metaImage={BrandImg}
        schemaType={TYPES.HOME}
        noIndex={true}
      />
      <Hero subtitle=" " hidePortals>
        <HeroTitle />
      </Hero>
      <Portals />
      <ESTAS
        title="We sell your home faster — and for more — than any other top UK agent.*"
        subtitle="There's a reason we're the Online Estate Agent of the Year for three years running."
        noCta
      />
      <SavingsSlider title="See how much you could save when you sell your home for free" />
      <Checklist
        title="What's included for free with Strike"
        list={CHECKLIST}
      />
      <HowWeSellForFree />
      <BookVal />
      <GreyWrapper>
        <Disclaimer />
      </GreyWrapper>
      <Footer />
    </Layout>
  );
};

export default SellYourHomeForFree;
